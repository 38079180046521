import React from "react";

const App = () => {
  const location = typeof window !== "undefined" ? window.location : undefined;
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get("email"); // email: info@nbank.dev
  const action = urlParams.get("action") || "subscribe"; // email: info@nbank.dev

  React.useEffect(() => {
    if (email && action) {
      fetch(
        // "http://localhost:3003/dev/newsletter",
        "https://nzc6ks1tgg.execute-api.eu-central-1.amazonaws.com/live/newsletter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ emailAdd: email, action }),
        }
      ).then(async () => {
        window.location.href = "https://restaurant-visier.de";
      });
    }
  }, [email, action]);

  return (
    <span>
      {email && action === "unsubscribe"
        ? "Sie wurden erflogreich in unserem Newsletter ausgetragen."
        : email && action === "subscribe"
        ? "Sie wurden erflogreich in unserem Newsletter eingetragen."
        : "Es wurde keine E-Mail & Action übergeben"}
    </span>
  );
};

export default App;
